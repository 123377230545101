/* eslint-disable */
import HttpService from '../HttpService';
export default class UserTermsService {
  constructor() {/* eslint-disable */
    this._httpService = new HttpService(`security`);
  }

  GetUserTermsByUserId(userId) {
    return this._httpService.get('/use_terms/getUserTermsByUserId/'+ userId);
  }

}
